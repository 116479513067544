<template>
    <div class="clear_step">
        <img class="clear_step_img" src="@/assets/huiheng/clear_step.png" alt="">
    </div>
</template>

<script>
export default {
    __name: "clear_step"
}
</script>

<style lang="scss" scoped>
.clear_step {
    // background: red;
}
.clear_step_img {
    width: 70%;
    // background: red;
    margin-left: 15%;
}
</style>