<template>
    <div class="certificate">
        <img src="@/assets/huiheng/rongyu.png" alt="">
        <!-- <h2 class="title">「<span>荣誉证书</span> 我们的荣誉」</h2>
        <el-carousel :interval="3000" type="card" height="400px">
            <el-carousel-item v-for="item in 3" :key="item">
                <img class="icons" :src="require(`@/assets/huiheng/zhengshu/img${item}.jpeg`)" alt="">
            </el-carousel-item>
        </el-carousel> -->
    </div>
</template>

<script>
export default {
    __name: "guanyu_zheng"
}
</script>

<style lang="scss" scoped>

.title {
    text-align: center;
    padding: 38px 0;
    font-size: 45px;
    span {
        color: #ed762f;
    }
}

.icons {
    width: 100%;
    height: 100%;
}

.certificate {
    // width: 1200px;
    // margin: 0 auto;
    padding: 50px 0;
    img {
        width: 100%;
    }
}

.el-carousel__item h3 {
    color: #475669;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
    text-align: center;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
</style>