<template>
  <div class="da_hezi">
    <div class="high-end-class">
      <h2 class="title">个性化学习</h2>
      <p class="sub_title">深度伴学 战则必胜</p>
    </div>
    <div class="banxing">
      <div class="center">
        <img class="banexing_img" src="@/assets/huiheng/gexing.png" alt="">
        <div class="ccc">
          <h1>精英青云班</h1>
          <p class="one_p">制定课程 全程辅导</p>
          <p>DEVELOP FULL COURSE COAHING</p>
        </div>
      </div>
      <div class="right">
        <div class="moeny">
          <p>¥<span> 15980 </span>元/全科 <del>原价 19980元</del></p>
          <div class="button">立即购买</div>
        </div>
      </div>
    </div>
    <div class="jieshao">
      <ul class="jieshao_ul">
        <li>
          <img src="@/assets/huiheng/gexinghua/ge1.png" alt="">
          <h3>制定学习计划</h3>
          <p>入班后进行入学测评，班主任依据学员入学测评结果等结合实际情况，制定专属学习计划</p>
        </li>
        <li>
          <img src="@/assets/huiheng/gexinghua/ge2.png" alt="">
          <h3>跟踪学习进度</h3>
          <p>根据学员学习情况不断优化调整计划内容，确保为每一位学员提供个性化和专业化的学习支持</p>
        </li>
        <li>
          <img src="@/assets/huiheng/gexinghua/ge3.png" alt="">
          <h3>在线答疑</h3>
          <p>答疑老师均为知识丰富的学科老师，为学员及时解决学习过程中遇到的痛点和难点，大幅度提升备考效率</p>
        </li>
        <li>
          <img src="@/assets/huiheng/gexinghua/ge4.png" alt="">
          <h3>签署服务协议</h3>
          <p>双方签署服务协议，最大程度保障学员权益，一次未过，次年免费重修学习，让每一位学员学习更加安心，无任何后顾之忧</p>
        </li>
        <li>
          <img src="@/assets/huiheng/gexinghua/ge5.png" alt="">
          <h3>一站式伴学</h3>
          <p>打造了从入学测评、计划制定到督学、上课提醒、实时答疑、筛选岗位、报岗协助等一站式伴学</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  __name: "high-end-class"
}
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  font-size: 20px;
  padding-top: 40px;
  font-size: 36px;
  color: #fff;

}

.sub_title {
  color: #fff;
  text-align: center;
  padding-top: 12px;
}

.da_hezi {
  width: 100%;
  padding-bottom: 50px;
}

.high-end-class {
  width: 100%;
  height: 320px;
  margin: 0 auto;
  background: #ed6b69;
}

.banxing {
  margin-top: -130px;
  width: 1200px;
  height: 170px;
  margin-left: 50%;
  border-radius: 10px;
  transform: translateX(-50%);
  background: #e54b42;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .center {
    display: flex;
    align-items: center;

    .banexing_img {
      width: 170px;
      margin-top: 20px;
      margin-left: 50px;
    }

    .ccc {
      margin-left: 30px;
      color: #fff;

      h1 {
        margin-bottom: 20px;
        font-style: italic;
      }
    }
  }

  .right {
    margin-right: 50px;

    .moeny {
      color: #fff;

      p {
        margin-bottom: 15px;

        span {
          font-size: 38px;
          font-weight: bold;
        }
      }

      .button {
        background: #f6c7c6;
        color: #000;
        width: 120px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-radius: 17.5px;
        margin-left: 182px;
      }
    }
  }

}

.jieshao_ul {
  width: 1200px;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  font-weight: bold;

  li {
    border-radius: 10px;
    height: 290px;
    box-shadow: 0 0px 10px 5px rgba(#000000, 0.05);
    width: 18%;
    text-align: center;

    h3 {
      margin: 15px 0;
    }

    img {
      margin-top: 20px;
      width: 65px;
    }

    p {
      width: 90%;
      margin-left: 5%;
      font-size: 15px;
      margin-top: 25px;
      color: #6f6f6f;
      // text-align: left;
    }
  }
}
</style>