<template>
    <div class="study-system">
        <h2 class="title"><span>「慧恒教育</span>-<span>AI智能</span>学习系统」</h2>
        <div class="sub_title">
            AI INTELLIGENT LEARNING SYSTEM
        </div>
        <div class="bottom_title">学习省心高效 <span>助力高效学习</span></div>
        <!-- <img src="@/assets/huiheng/ai.png" alt=""> -->
        <use-button class="sub_button">立即体验</use-button>
    </div>
</template>

<script>
export default {
    __name: 'study-system'
}
</script>

<style lang="scss" scoped>
.title {
    text-align: center;
    padding: 38px 0;
    font-size: 62px;
    letter-spacing: 5px;
    position: absolute;
    top: 12%;
    left: 51%;
    transform: translateX(-50%);

    span {
        color: red;
        // background-image: -webkit-linear-gradient(right, #fe6b48, #fd8241, #cf3031);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
    }
}

.sub_title {
    position: absolute;
    left: 54.5%;
    top: 25%;
    font-size: 20px;
    color: #666666;
}

.sub_button {
    position: absolute;
    bottom: 3%;
    left: 51%;
    transform: translateX(-50%);
}

.bottom_title {
    text-align: center;
    // padding: 38px 0;
    font-size: 58px;
    letter-spacing: 3px;
    position: absolute;
    bottom: 11%;
    left: 51%;
    font-weight: bold;
    transform: translateX(-50%);
    span {
        color: red;
    }
}

.study-system {
    position: relative;
    min-height: 1000px;
    width: 100%;
    // width: 1200px;
    // margin: 0 auto;
    background: url('@/assets/huiheng/ai.png') no-repeat;
    background-size: 100% 100%;

    .body {
        display: flex;
        height: 550px;
        border: 1px solid #e4e4e4;

        li {
            width: 300px;
            height: 550px;
            text-align: center;
            overflow: hidden;
            position: relative;

            .shadow {
                // width: 300px;
                // height: 550px;
                width: 0;
                height: 0;
                transition: all 0.3s;
                position: absolute;
                top: 0px;
                background: rgba(241, 106, 93, 0.9);
                z-index: 2;
                opacity: 0;

                .shadow-title {
                    display: block;
                    width: 243px;
                    margin-top: 26px;
                    text-align: left;
                    font-size: 24px;
                    color: #fff;
                    font-weight: normal;
                    margin-left: 50%;
                    transform: translateX(-50%);
                }

                li {
                    width: 243px;
                    height: 53px;
                    background: url('@/assets/zhuanti/bg_12.png') no-repeat center center;
                    background-size: 243px 53px;
                    margin: 15px 0;
                    color: #fff;
                    margin-left: 30px;

                    h3 {
                        text-align: left;
                        font-size: 16px;
                        color: #f16a5d;
                        font-weight: normal;
                        margin-left: 8px;
                        padding-top: 3px;
                    }

                    h4 {
                        font-size: 12px;
                        color: #fff;
                        font-weight: normal;
                        text-align: left;
                        margin-top: 7px;
                        margin-left: 9px;
                    }
                }

                .shadow-newsTitle {
                    width: 243px;
                    text-align: left;
                    font-size: 16px;
                    color: #fff;
                    height: 36px;
                    line-height: 36px;
                    border-bottom: solid 1px #fff;
                    margin-left: 50%;
                    transform: translateX(-50%);
                }
            }

            .study-title {
                font-size: 34px;
                color: #333;
                font-weight: normal;
                margin-top: 20px;

            }

            .sub-span {
                display: inline-block;
                font-size: 18px;
                color: #fff;
                background: #f16a5d;
                height: 36px;
                line-height: 36px;
                padding: 0 12px;
                border-radius: 50px;
                margin-top: 10px;
            }

            img {
                display: inline-block;
                margin-top: 40px;
                width: 85%;
            }

            .bottomList {
                .span-cli {
                    width: 270px;
                    background-color: #f16a5d;
                    margin: 8px auto 0 auto;
                    border-radius: 10px;
                    padding: 9px 0;
                    color: #fff;

                    p {
                        font-size: 18px;
                        color: #fff;
                        padding-left: 15px;
                        text-align: left;
                        background: url('@/assets/zhuanti/d.jpg') no-repeat left center;
                        margin: 6px 0px 6px 45px;
                    }
                }
            }
        }

        li:hover {
            .shadow {
                opacity: 1;
                width: 300px;
                height: 550px;

            }
        }

        li:nth-child(2) {
            background: #ededed;
        }

        li:nth-child(4) {
            background: url('@/assets/zhuanti/kc1-4.jpg') no-repeat;
        }
    }
}
</style>