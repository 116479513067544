<template>
    <div class="problem">
        <p class="title">满足条件就能安心报考？这些问题你更得重视</p>
        <ul class="problem_list">
            <li>
                <div class="li_title">
                    <span class="span1">01</span>
                    <p>职位选择</p>
                </div>
                <p class="text">报考人员只能选择一个部门(单位)中的一个职位进行报名，提交的报+考申请材料应真实、准确。报考人员提供报考申请虚假信息的，一经查实，即取消报考资格。</p>
                <p class="button">职位指导</p>
            </li>
            <li>
                <div class="li_title">
                    <span class="span2">02</span>
                    <p>正确报名</p>
                </div>
                <p class="text">可登陆中央机关及其直属机构{{ time }}年度考试录用网站进行报名但由于每年都会有因为报考流程不正确，而白白浪费考试的机会。</p>
                <p class="button">报名指导</p>
            </li>
            <li>
                <div class="li_title">
                    <span class="span3">03</span>
                    <p>审核期间</p>
                </div>
                <p class="text">报考期间登陆考录专题网站查询是否通过了资格审查，通过资格审查的不能再报考其他职位。</p>
                <p class="button">资格复审</p>
            </li>
            <li>
                <div class="li_title">
                    <span class="span1">04</span>
                    <p>必考资料和课程</p>
                </div>
                <p class="text">科研组精编讲义+历年考试真题+精编直播录播课程，在线免费查看，可下载，让您国考少走弯路。</p>
                <p class="button">资料下载</p>
            </li>
            <li>
                <div class="li_title">
                    <span class="span2">05</span>
                    <p>笔试考试备考</p>
                </div>
                <p class="text">无论你是应届生还是在职人员，无论是第一次报考还是多次报考。找到适合自己的复习办法才是考试成功的唯一捷径。</p>
                <p class="button">笔试指导</p>
            </li>
            <li>
                <div class="li_title">
                    <span class="span3">06</span>
                    <p>面试考试备考</p>
                </div>
                <p class="text">面试考试极其考核考生的整体综合能力。考试仪态、个人陈述、答题结构化问答，每个环节都会导致面试分数。</p>
                <p class="button">面试指导</p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    __name: 'problem-view',
    data() {

    },
    computed: {
        time() {
            const CODE = {
                country: "2025",
                province: "2025",
                career: "2025",
                // troops: "军队文职",
            };
            return CODE[this.$route.name];
        }
    }
}
</script>

<style lang="scss" scoped>
.problem {
    padding: 57px 0 52px;
    width: 100%;
    background: url(@/assets/problem/question_bg.png) no-repeat center;
    background-size: 100% 100%;
    height: 839px;

    .title {
        text-align: center;
        background: url(@/assets/problem/title_bg3.png) no-repeat 50% 0%;
        background-size: 396px 25px;
        padding-top: 11px;
        color: #ffffff;
        letter-spacing: 3px;
        font-size: 38px;
        font-weight: bold;
    }

    .problem_list {
        width: 62%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-top: 55px;

        li {
            position: relative;
            width: 25.5%;
            // width: 300px;
            height: 310px;
            padding: 28px 24px 0;
            background-color: #fff;
            border-radius: 5px;
            margin-bottom: 36px;

            .li_title {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 12px;

                span {
                    display: inline-block;
                    color: #ffffff;
                    font-size: 20px;
                    width: 28px;
                    height: 28px;
                    text-align: center;
                    line-height: 28px;
                    border-radius: 50%;
                    margin-right: 6px;
                }

                p {
                    font-size: 22px;
                    color: #000000;
                    font-weight: bold;
                }

                .span1 {
                    background-color: #1257ee;
                }

                .span2 {
                    background-color: #e64466;
                }

                .span3 {
                    background-color: #85e3aa;
                }
            }

            .text {
                font-size: 18px;
                color: #333333;
                line-height: 30px;
                text-align: justify;
                text-align-last: left;
            }

            .button {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 22px;
                margin: 0 auto;
                display: block;
                width: 120px;
                height: 38px;
                line-height: 38px;
                text-align: center;
                font-size: 20px;
                color: #ffffff;
                border-radius: 5px;
                background-color: #e0635f;
            }
        }
    }
}
</style>