<template>
  <div class="new_tifen">
    <div class="centerBody fen_body">
      <h2 class="title">精心<span>打磨</span>每一堂课 尽心<span>关注</span>每位学员</h2>
      <!-- <div class="sub_title">
        <p>用英语考研没有信心，想报的专业又允许使用日语参加考试？</p>
      </div> -->
      <div class="tifen_body">
        <div class="middle">
          <p>为什么选择<br>慧恒教育</p>
        </div>
        <div class="item item1">
          <div class="item_title">
            十年匠心 深耕教育
          </div>
          <div class="text">
            慧恒教育课程专注于考公考编培训，授课宗旨：给知识，建体系；给思想，建逻辑，十年砥砺前行。
          </div>
        </div>
        <div class="item item2">
          <div class="item_title">
            自主研发核心资料+大数据题库
          </div>
          <div class="text">
            由慧恒教育教研团队利用大数据分析系统，分析近三年命题走向及考生丢分题、易错题、必考题，深度预测命题走势，研发配套核心考试资料，并开发大数据支撑的APP-准题库。 </div>
        </div>
        <div class="item item3">
          <div class="item_title">
            自主学习+快乐学习
          </div>
          <div class="text">
            慧恒教育拥有讲师多名，打造了线上班主任团队，在线领学，充分发挥线上教学优势，引导学员自主学习、快乐学习。
          </div>
        </div>
        <div class="item item4">
          <div class="item_title">
            微信领学+云课堂+准题库
          </div>
          <div class="text">
            随着移动互联网的普及，慧恒教育紧跟科技潮流，开创微信领学机制，让学员主动学习，利用碎片化时间深度学习。并将视频课程和PC题库，颠覆性升级为云课堂教学和手机APP题库。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.title {
  font-size: 40px;
  text-align: center;
  margin-bottom: 30px;
  span {
    color: #db1d25;
  }
}

.new_tifen {
  width: 100%;
  min-height: 640px;
  background: url(@/assets/huiheng/kaoyan/page1_02.jpg) no-repeat;
  background-size: 100% 100%;
  margin-top: -15px;

  .fen_body {
    position: relative;
    padding: 50px 0;
    .item {
      position: absolute;
      width: 346px;
      min-height: 112px;
      background: #fff;
      box-shadow: 2px 2px 5px rgba(181, 201, 221, 0.29);
      box-sizing: border-box;
      padding-left: 23px;
      border-radius: 10px;
      padding-bottom: 10px;

      .item_title {
        margin-top: 12px;
        font-size: 18px;
        color: #444444;
        font-weight: bold;
        position: relative;
        padding-left: 20px;
        margin-bottom: 5px;
      }

      .item_title::before {
        content: "";
        width: 15px;
        height: 15px;
        background: #0096ff;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 4px;
      }

      .text {
        width: 95%;
        font-size: 14px;
        color: #6b6b6b;
      }
    }

    .item1 {
      left: 110px;
      top: 173px;
    }

    .item2 {
      left: 110px;
      top: 373px;
    }

    .item3 {
      right: 115px;
      top: 173px;
    }

    .item4 {
      right: 115px;
      top: 373px;
    }
  }

  .sub_title {
    color: #6b6b6b;
    font-size: 22px;
    text-align: center;
    padding-top: 82px;
    box-sizing: border-box;
    margin-bottom: 66px;
    // position: relative;
  }

  // .sub_title p::before {
  //   content: '';
  //   width: 25px;
  //   height: 30px;
  //   background: url(@/assets/huiheng/kaoyan/k.png) no-repeat;
  //   position: absolute;
  //   left: 330px;
  //   top: 110px;
  // }

  .tifen_body {
    padding-top: 30px;

    .middle {
      width: 343px;
      height: 335px;
      margin-left: 50%;
      transform: translateX(-50%);
      background: url(@/assets/huiheng/kaoyan/si2_03.png) no-repeat;

      p {
        width: 100%;
        height: 100%;
        font-size: 24px;
        line-height: 36px;
        color: #2c2c2c;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }
}
</style>