<template>
    <div class="exam_precise">
        <h2 class="title">舍弃无用教材 掌握<span>精准考点</span></h2>
        <p class="sub_title">集中时间和精力去记忆重要部分</p>
        <img class="exam_img" src="@/assets/huiheng/exam_precise.png" alt="">
    </div>
</template>

<script>
export default {
    __name: 'exam_precise'
}
</script>

<style scoped lang="scss">
.title {
    text-align: center;
    padding: 38px 0;
    font-size: 45px;

    span {
        background-image: -webkit-linear-gradient(right, #fe6b48, #fd8241, #cf3031);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.sub_title {
    text-align: center;
    font-size: 18px;
    margin: -15px 0 20px 0;
    color: #999;
}

.exam_precise {
    padding: 50px 0;

    .exam_img {
        width: 70%;
        margin-left: 15%;
    }
}
</style>