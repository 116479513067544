<template>
    <div class="tall_land">
        <img class="tall_img" src="@/assets/huiheng/shangan.jpg" alt="">
    </div>
</template>

<script>
export default {
    __name: "tall_land"
}
</script>

<style lang="scss" scoped>
.tall_land {
    width: 100%;
    // background: red;
}
.tall_img {
    width: 100%;
}
</style>