<template>
  <div class="five_key">
    <ul class="five_ul">
      <li>
        <div class="left">
          <h2 class="title">夯实基础</h2>
          <div class="number">1</div>
        </div>
        <div class="right">
          <p>系统讲解知识点理论，熟悉考试框架，梳理学习思路，掌握基本的解题技巧和方法，以及常考点、必考点、重难点、高频考点</p>
        </div>
      </li>
      <li>
        <div class="left">
          <h2 class="title">技巧提升</h2>
          <div class="number">2</div>
        </div>
        <div class="right">
          <p>在扎实的基本知识之上，通过大量的习题练习，包括技巧提升课的培训，帮助学员找到快速解题办法，为考试提供更充裕的时间</p>
        </div>
      </li>
      <li>
        <div class="left">
          <h2 class="title">强化冲刺</h2>
          <div class="number">3</div>
        </div>
        <div class="right">
          <p>教研团队老师会根据当年考试大纲和历年考试情况进行组卷，为学员在考前提供大量题目进行练习，熟能生巧，强化考试核心内容，掌握秒题技巧、临时猜题技巧，培养做题思维，确保沉着应对考试</p>
        </div>
      </li>
      <li>
        <div class="left">
          <h2 class="title">选岗指导</h2>
          <div class="number">4</div>
        </div>
        <div class="right">
          <p>根据学员平时的模拟成绩，专业、报考省份、个人意向等，对比往年岗位的进面分数线以及报考人数等数据精准选岗的，确保稳妥上岸</p>
        </div>
      </li>
      <li>
        <div class="left">
          <h2 class="title">独家密训</h2>
          <div class="number">5</div>
        </div>
        <div class="right">
          <p>考情分析，押题预测，高频考点, 独家密训课，助力上岸</p>
        </div>
      </li>
      <li class="tubiao">
        <img src="@/assets/huiheng/upup.png" alt="">
      </li>
    </ul>
  </div>
</template>

<script>
export default {
    __name: 'five_key'
}
</script>

<style lang="scss" scoped>
.five_key {
  width: 100%;
  min-height: 500px;
  background: url('@/assets/huiheng/five.png') no-repeat;
  background-size: 100% 100%;
}

.five_ul {
  width: 1000px;
  padding: 50px 0;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  li {
    width: 40%;
    height: 250px;
    margin-bottom: 30px;
    position: relative;
    .left {
      position: absolute;
      left: 0;
      top: 0;
      width: 150px;
      height: 215px;
      background: url('@/assets/huiheng/lan.png') no-repeat;
      background-size: 100% 100%;
      text-align: center;
      color: #fff;
      z-index: 10;
      .title {
        margin-top: 20px;
      }
      .number {
        font-size: 26px;
        font-weight: bold;
        margin-top: 45px;
      }
    }
    .right {
      position: absolute;
      right: 0;
      top: 20px;
      width: 360px;
      height: 210px;
      background: #fff;
      display: flex;
      align-items: center;
      p {
        margin-left: 130px;
        margin-right: 10px;
      }
    }
  }
  .tubiao {
    img {
      width: 100%;
    }
  }
}
</style>